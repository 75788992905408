import React, { useState } from "react";
import "./contact-form.css";
import { toast } from "react-toastify";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    userName: "",
    phoneNo: "",
    userEmail: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.userName) formErrors.userName = "Name is required";
    if (!formData.phoneNo) formErrors.phoneNo = "Phone number is required";
    if (!formData.userEmail) {
      formErrors.userEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.userEmail)) {
      formErrors.userEmail = "Email is invalid";
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const [response1, response2, response3] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/contact/add`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }),
          fetch(`${process.env.REACT_APP_API_URL}/contact/userEmail`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userName: formData.userName,
              userEmail: formData.userEmail,
              phoneNo: formData.phoneNo,
              message: formData.message,
            }),
          }),
          fetch(`${process.env.REACT_APP_API_URL}/contact/adminEmail`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userName: formData.userName,
              userEmail: formData.userEmail,
              phoneNo: formData.phoneNo,
              message: formData.message,
            }),
          }),
        ]);

        if (response1.ok && response2.ok && response3.ok) {
          toast.success("Your message has been sent successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });
          setFormData({
            userName: "",
            phoneNo: "",
            userEmail: "",
            message: "",
          });
          setErrors({});
        } else {
          toast.error("There was an error sending your message.", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("There was an error sending your message.", {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formErrors = validateForm();
  //   if (Object.keys(formErrors).length === 0) {
  //     try {
  //       const response1 = await fetch(
  //         `${process.env.REACT_APP_API_URL}/contact/add`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(formData),
  //         }
  //       );

  //       const response2 = await fetch(
  //         `${process.env.REACT_APP_API_URL}/contact/userEmail`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             userName: formData.userName,
  //             userEmail: formData.userEmail,
  //             phoneNo: formData.phoneNo,
  //             message: formData.message,
  //           }),
  //         }
  //       );

  //       if (response1.ok && response2.ok) {
  //         toast.success("Your message has been sent successfully!", {
  //           autoClose: 1000,
  //           position: "bottom-right",
  //         });
  //         setFormData({
  //           userName: "",
  //           phoneNo: "",
  //           userEmail: "",
  //           message: "",
  //         });
  //         setErrors({});
  //       } else {
  //         toast.error("There was an error sending your message.", {
  //           autoClose: 1000,
  //           position: "bottom-right",
  //         });
  //       }
  //     } catch (error) {
  //       toast.error("There was an error sending your message.", {
  //         autoClose: 1000,
  //         position: "bottom-right",
  //       });
  //     }
  //   } else {
  //     setErrors(formErrors);
  //   }
  // };

  return (
    <>
      <div className="contact-body-section py-5">
        <div className="our-container">
          <div className="contact-body-row">
            <div className="contact-body-content-block">
              <div className="h2">Let's Grow Your Business Together!</div>
              <div className="p">
                Get in touch with us today to discuss your project.
              </div>
              <p>
                Ready to take your business to new heights with innovative
                digital marketing strategies? KARN Marketing Warfare LLP is here
                to help you achieve your goals.
              </p>
            </div>
            <div className="contact-body-form-block">
              <div className="h3">Let's talk</div>
              <form onSubmit={handleSubmit}>
                <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                  <input
                    type="text"
                    id="name"
                    name="userName"
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Name*"
                    value={formData.userName}
                    onChange={handleInputChange}
                  />
                  {errors.userName && (
                    <div className="text-danger">{errors.userName}</div>
                  )}
                </div>
                <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                  <input
                    type="number"
                    id="number"
                    name="phoneNo"
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Number*"
                    value={formData.phoneNo}
                    // onChange={handleInputChange}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .slice(0, 10)
                        .replace(/\D/g, "");
                      handleInputChange(e); // Ensure this triggers the state update
                    }}
                  />
                  {errors.phoneNo && (
                    <div className="text-danger">{errors.phoneNo}</div>
                  )}
                </div>
                <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                  <input
                    type="email"
                    id="email"
                    name="userEmail"
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Email*"
                    value={formData.userEmail}
                    onChange={handleInputChange}
                  />
                  {errors.userEmail && (
                    <div className="text-danger">{errors.userEmail}</div>
                  )}
                </div>
                <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                  <textarea
                    name="message"
                    id="message"
                    rows={"3"}
                    autoComplete="off"
                    className="p-2 rounded-0"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="text-capitalize contact-form-button"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
