import React, { useEffect, useState } from "react";
import "./blog-detail.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import ContactForm from "../../components/ContactForm";
import MetaDescTag from "../../components/MetaDescTag";
import { blogs } from "../../staticData/data";

const BlogDetail = () => {
  const { slug } = useParams();
  const blog = blogs.find((item) => item.slug === slug); // Find blog by slug

  const renderContent = (content) => {
    return content.map((item, index) => {
      switch (item.type) {
        case "heading":
          return (
            <div className="h3" key={index} style={{ color: "#ffffff" }}>
              {item.data}
            </div>
          );
        case "paragraph":
          return (
            <p key={index} style={{ color: "#ffffff" }}>
              {item.data}
            </p>
          );
        case "list":
          return (
            <ul key={index} style={{ color: "#ffffff" }}>
              {item.data.map((listItem, i) => (
                <li key={i} style={{ color: "#ffffff" }}>
                  {listItem}
                </li>
              ))}
            </ul>
          );
        default:
          return null;
      }
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
    webUrl: "",
    slugTitle: slug || "", // Initialize slugTitle with the slug
  });
  const [comments, setComments] = useState([]);

  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Update slugTitle if the slug changes
    setFormData((prevFormData) => ({
      ...prevFormData,
      slugTitle: slug || "",
    }));
  }, [slug]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/blog/search`,
          {
            pkBlogCmtId: "",
            name: "",
            email: "",
            comment: "",
            webUrl: "",
            isActive: "",
            slugTitle: slug,
          }
        );

        if (response.status === 200) {
          const activeComments = response.data.data.filter(
            (comment) =>
              comment.blogName === formData.slugTitle &&
              comment.isActive === "1"
          );
          setComments(activeComments);
        } else {
          toast.error("Failed to fetch comments!", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        // console.log(error);
        // toast.error("Error fetching comments!", {
        //   autoClose: 1000,
        //   position: "bottom-right",
        // });
      }
    };

    fetchComments();
  }, [slug, formData.slugTitle]); // Dependency on slug and slugTitle

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", email: "" };

    // Check if name is empty
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }

    // Check if email is empty or invalid
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submitting
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/blog/add`,
        {
          name: formData.name,
          email: formData.email,
          comment: formData.comment,
          webUrl: formData.webUrl,
          slugTitle: formData.slugTitle,
          isActive: 0,
        }
      );

      if (response.status === 201) {
        toast.success("Comment posted successfully!", {
          autoClose: 1000,
          position: "bottom-right",
        });
        setFormData({
          name: "",
          email: "",
          comment: "",
          webUrl: "",
          slugTitle: slug || "",
        });
      }
    } catch (error) {
      toast.error("There was an error posting the comment!", {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const formatDate = (dateArray) => {
    const date = new Date(
      dateArray[0], // year
      dateArray[1] - 1, // month (0-indexed)
      dateArray[2], // day
      dateArray[3], // hour
      dateArray[4], // minute
      dateArray[5], // second
      dateArray[6] / 1000000 // millisecond
    );
    return date.toLocaleString(); // Format date to string
  };

  return (
    <>
      <MetaDescTag
        title={blog.blogTitle + " | KARN Marketing Warfare LLP"}
        description={blog.blogDesc}
      />
      <section className="blog-detail-section position-relative">
        <div className="blog-detail-hero-section">
          <div className="blog-detail-hero-section-content"></div>
        </div>
        <div className="blog-detail-body-section py-5">
          <div className="our-container">
            <div className="inner-container">
              <div className="blog-detail-section-block">
                <div className="blog-detail-section-block-img">
                  <img src={blog.blogImg} alt="" />
                </div>
                <div className="blog-detail-section-block-content">
                  <h1 className="text-capitalize">{blog.blogTitle}</h1>
                  <p>{blog.blogDesc}</p>
                  {renderContent(blog.content)}
                </div>
              </div>
              {comments.length > 0 && (
                <div className="blog-form-comment-show-section-block">
                  <div className="h5 mb-3">Comments</div>
                  <ul className="p-0">
                    {comments?.map((comment) => {
                      return (
                        <li key={comment.pkBlogCmtId}>
                          <div className="comment-show">
                            <div className="comment-show-header">
                              <div className="comment-header-show-sub">
                                <div className="first-letter">
                                  {comment?.name[0]}
                                </div>
                                <div className="full-name">{comment?.name}</div>
                              </div>
                              <div className="comment-timing">
                                {formatDate(comment.createdDate)}
                              </div>
                            </div>
                            <div className="comment-show-body">
                              <p className="mb-0">{comment?.comment}</p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div className="blog-form-section-block">
                <div className="h5">Leave a Comment</div>
                <p>
                  Your email address will not be published. Required fields are
                  marked *
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex align-align-items-center flex-column mb-4 blog-comment-form-group">
                    <input
                      type="text"
                      id="slugTitle"
                      name="slugTitle"
                      className="px-2 rounded-0"
                      value={formData.slugTitle}
                      readOnly
                    />
                  </div>
                  <div className="d-flex align-align-items-center flex-column mb-4 blog-comment-form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="off"
                      className="px-2 rounded-0"
                      placeholder="Your Name*"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p
                        className="error-message text-danger mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.name}
                      </p>
                    )}
                  </div>
                  <div className="d-flex align-align-items-center flex-column mb-4 blog-comment-form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="off"
                      className="px-2 rounded-0"
                      placeholder="Your Email*"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p
                        className="error-message text-danger mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="d-flex align-align-items-center flex-column mb-4 blog-comment-form-group">
                    <input
                      type="text"
                      id="webUrl"
                      name="webUrl"
                      autoComplete="off"
                      className="px-2 rounded-0"
                      placeholder="Your Website*"
                      value={formData.webUrl}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex align-align-items-center flex-column mb-4 blog-comment-form-group">
                    <textarea
                      name="comment"
                      id="message"
                      rows={"3"}
                      autoComplete="off"
                      className="p-2 rounded-0"
                      placeholder="Your Message*"
                      value={formData.comment}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="text-capitalize blog-comment-form-button"
                  >
                    Post Comment
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default BlogDetail;
